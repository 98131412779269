<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox class="mr-2" v-model="isDeleteMultipleCandChecked">
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="7">
            <div
              v-if="selectedCustomFields.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search Name"
                />
              </b-input-group>

              <b-button
                variant="primary"
                class="text-nowrap"
                v-b-modal.modal-prevent-closing
              >
                Add Custom Field
              </b-button>
            </div>
            <b-button
              v-else
              class="float-right"
              variant="danger"
              :disabled="customfieldsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ customfieldsDeleteCount }} Custom Field</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedCustomFields"
        name="selectedCustomFields"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchCustomFieldList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{
                  name: 'view-customfield',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
            </b-media>
          </template>

          <template #cell(status_label)="data">
            <span
              :style="{
                color:
                  data.item.status_label == 'Selected'
                    ? 'green'
                    : data.item.status_label == 'Rejected'
                    ? 'red'
                    : 'grey',
              }"
              >{{ data.item.status_label }}</span
            >
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <span>
              <feather-icon
                v-b-tooltip.hover.top="'Edit Custom Field'"
                class="btn-link cursor-pointer"
                icon="EditIcon"
                @click="editRecord(data.item)"
              />
              |
              <feather-icon
                v-b-tooltip.hover.top="'Delete Custom Field'"
                class="btn-link cursor-pointer"
                icon="TrashIcon"
                @click="confirmDeleteRecord(data)"
              />
            </span>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-if="selectedCustomFields.length == 0"
              v-model="currentPage"
              :total-rows="totalCustomFieldList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else
              class="float-right"
              variant="danger"
              :disabled="customfieldsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ customfieldsDeleteCount }} Custom Field</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Custom Field Add"
      @ok="storeData"
    >
      <div v-if="false">
        <b-form-group id="input-group-1" label-for="input-1" label="Module">
          <b-form-select
            v-model="localData.module"
            :options="customFieldGroups"
            placeholder="Your Field Module"
            name="module"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group id="input-group-1" label-for="input-1" label="Label">
          <b-form-input
            id="input-1"
            v-model="localData.label"
            placeholder="Your Field Label"
            name="label"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group id="input-group-1" label-for="input-1" label="Name">
          <b-form-input
            id="input-1"
            v-model="localData.name"
            placeholder="Your Field Name"
            name="name"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group id="input-group-12" label-for="input-1" label="Required">
          <b-form-radio-group
            id="radio-group-1"
            v-model="localData.required"
            :options="requiredOptions"
            name="required"
          >
          </b-form-radio-group>
        </b-form-group>
        <b-form-group id="input-group-1" label-for="input-1" label="Type">
          <b-form-select
            v-model="localData.type"
            :options="typeOptions"
            placeholder="Your Field Type"
            name="type"
          >
          </b-form-select>
        </b-form-group>

        <table>
          <tbody>
            <tr v-for="(item, index) in localData.values" v-bind:key="index">
              <td>
                <b-form-group
                  id="input-group-1"
                  label-for="input-1"
                  label="Value"
                >
                  <b-form-input
                    id="input-1"
                    placeholder="Your Field Value"
                    name="values"
                  >
                  </b-form-input>
                </b-form-group>
              </td>
              <td>
                <b-button variant="danger" size="sm" @click="removeItem()">
                  Remove
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
        <b-button variant="success" size="sm" @click="addItem()">
          Add More
        </b-button>
      </div>

      <b-form-group
        v-for="(field, index) in schema.fields"
        v-bind:key="index"
        :id="`input-group-${index}`"
        :label="field.label"
      >
        <b-form-input
          v-if="field.type === 'input'"
          id="input-1"
          v-model="schema.fields[index].model"
          :type="field.inputType"
          :placeholder="field.placeholder"
          :name="field.model"
        >
        </b-form-input>

        <b-form-radio-group
          v-if="field.type === 'radio'"
          :id="`radio-group-${index}`"
          v-model="schema.fields[index].model"
          :options="field.options"
        >
        </b-form-radio-group>

        <b-form-select
          v-if="field.type === 'select'"
          v-model="schema.fields[index].model"
          :options="field.options"
          :name="field.model"
        >
        </b-form-select>
      </b-form-group>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="sm" variant="success" @click="ok()"> Save </b-button>
      </template>
    </b-modal>
    <b-modal
      id="custom-field-edit-modal"
      ref="modal"
      title="Custom Field Edit"
      @ok="updateData"
    >
      <div>
        <b-form-group id="input-group-1" label-for="input-1" label="Module">
          <b-form-select
            v-model="localData.module"
            :options="customFieldGroups"
            placeholder="Your Field Module"
            name="module"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group id="input-group-1" label-for="input-1" label="Label">
          <b-form-input
            id="input-1"
            v-model="localData.label"
            placeholder="Your Field Label"
            name="label"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group id="input-group-1" label-for="input-1" label="Name">
          <b-form-input
            id="input-1"
            v-model="localData.name"
            placeholder="Your Field Name"
            name="name"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group id="input-group-1" label-for="input-1" label="Required">
          <b-form-radio-group
            id="radio-group-1"
            v-model="localData.required"
            :options="requiredOptions"
            name="required"
          >
          </b-form-radio-group>
        </b-form-group>
        <b-form-group id="input-group-1" label-for="input-1" label="Type">
          <b-form-select
            v-model="localData.type"
            :options="typeOptions"
            placeholder="Your Field Type"
            name="type"
          >
          </b-form-select>
        </b-form-group>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="sm" variant="success" @click="ok()"> Update </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BFormSelect,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import customFieldStoreModule from "../customFieldStoreModule";
import useCustomFieldsList from "./useCustomFieldList";
import InterviewAdd from "../../interviews/list/InterviewAdd.vue";
import FormGenerator from "/src/components/FormGenerator.vue";
import axios from "@axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BButton,
    BTable,
    BMedia,
    BLink,
    BFormGroup,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    InterviewAdd,
    BFormRadioGroup,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
    FormGenerator,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-custom-fields";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedCustomFields = ref([]);
    const customfieldsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        customFieldStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];

    const {
      fetchCustomFieldList,
      tableColumns,
      perPage,
      currentPage,
      totalCustomFieldList,
      customFieldsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
    } = useCustomFieldsList();

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedCustomFields.value = [];
      if (val) {
        customFieldsMetaData.value.forEach((customfield) => {
          selectedCustomFields.value.push(customfield.hashid);
        });
      }
    });

    watch(selectedCustomFields, (val) => {
      customfieldsDeleteCount.value = val.length;
    });

    return {
      // Sidebar
      fetchCustomFieldList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalCustomFieldList,
      // Extra Filters
      statusOptions,
      statusFilter,
      selectedCustomFields,
      customfieldsDeleteCount,
      isDeleteMultipleCandChecked,
    };
  },
  created() {
    this.fetchCustomFieldGroups();
  },
  data() {
    return {
      localData: {
        module: "",
        label: "",
        name: "",
        required: "",
        type: null,
        value: [],
      },
      requiredOptions: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      typeOptions: [
        {
          text: "-- Please select an option --",
          value: null,
          disabled: true,
        },
        { text: "Text", value: "text" },
        { text: "Number", value: "number" },
        { text: "Password", value: "password" },
        { text: "Date", value: "date" },
        { text: "Rating", value: "rating" },
        // { text: "Select", value: "select" },
        // { text: "Dropdown", value: "dropdown" },
        // { text: "Checkbox", value: "checkbox" },
        // { text: "Radio", value: "radio" },
        { text: "Textarea", value: "textarea" },
        { text: "URL", value: "url" },
        // { text: "Textarea+Rating", value: "textarea_rating" },
      ],
      customFieldGroups: [],
      testLabels: "chandra",
      model: {
        module: "",
        label: "tushar Name",
        name: "name",
        required: "",
        type: null,
      },
      schema: {
        fields: [
          {
            type: "select",
            label: "Module",
            name: "custom_field_group_id",
            model: "",
            options: [],
          },
          {
            type: "input",
            inputType: "text",
            label: "Label",
            name: "label",
            model: "",
            placeholder: "Your Field Label",
          },
          {
            type: "input",
            inputType: "text",
            label: "Name",
            name: "name",
            model: "",
            placeholder: "Your Field Name",
          },
          {
            type: "radio",
            label: "Required",
            name: "required",
            model: "",
            placeholder: "Your Field Required",
            options: [
              {
                text: "Yes",
                value: "yes",
              },
              {
                text: "No",
                value: "no",
              },
            ],
          },
          {
            type: "select",
            label: "Type",
            name: "type",
            model: "",
            options: [
              {
                text: "-- Please select an option --",
                value: null,
                disabled: true,
              },
              { text: "Text", value: "text" },
              { text: "Number", value: "number" },
              { text: "Password", value: "password" },
              { text: "Rating", value: "rating" },
              { text: "Date", value: "date" },
              // { text: "Select", value: "select" },
              // { text: "Dropdown", value: "dropdown" },
              // { text: "Checkbox", value: "checkbox" },
              // { text: "Radio", value: "radio" },
              { text: "URL", value: "url" },
              { text: "Textarea", value: "textarea" },
              // { text: "Textarea+Rating", value: "textarea_rating" },
            ],
          },
        ],
      },
    };
  },
  methods: {
    storeData() {
      const self = this;
      //create form data
      const formData = new FormData();

      // for loop to get all the form data
      this.schema.fields.forEach((field) => {
        formData.append(field.name, field.model);
      });

      //send form data to server

      this.$store
        .dispatch("app-custom-fields/addCustomField", formData)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Custom Field Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          self.refetchData();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Custom Field Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    fetchCustomFieldGroups() {
      const self = this;
      this.$store
        .dispatch("app-custom-fields/fetchCustomFieldGroups")
        .then((res) => {
          if (res.data.success) {
            self.customFieldGroups = res.data.data;
            self.schema.fields[0].options = self.customFieldGroups;
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
    onTypeChange(value) {
      console.log(value);
    },
    onLableChange(value) {
      console.log(this.model.modulealue);
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.label}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.id);
        }
      });
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-custom-fields/removeCustomfield", id)
        .then((res) => {
          if (res.status == "204") {
            self.refetchData();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Custom field Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Custom field deleted successfully",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Custom field Deletion failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedCustomFields.length} CustomFields, This canot be undone`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "app-custom-fields/removeMultipleCustomFields",
              this.selectedCustomFields
            )
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedCustomFields = [];
              if (res.data.success) {
                this.refetchData();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Deleted Successfully",
                    icon: "BellIcon",
                    variant: "success",
                    text: res.data.message,
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "CustomField Deletion failed",
                    icon: "BellIcon",
                    variant: "danger",
                    text: res.data.message,
                  },
                });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    editRecord(data) {
      this.localData = data;
      this.localData.module = data.custom_field_group_id;
      this.$bvModal.show("custom-field-edit-modal");
    },
    updateData() {
      const self = this;
      var form_data = new FormData();
      for (var key in this.localData) {
        form_data.append(key, this.localData[key]);
      }

      form_data.append("_method", "PATCH");

      //send form data to server
      this.$store
        .dispatch("app-custom-fields/updateCustomField", {
          id: this.localData.id,
          data: form_data,
        })
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Custom Field Updated",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          self.refetchData();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Custom Field Updating Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
